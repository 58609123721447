import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Scrollbar, Mousewheel, Navigation, Pagination, Controller, Thumbs } from 'swiper';
import axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import 'swiper/swiper-bundle.css';
import {Collapse} from 'react-collapse';

import './styles.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  "max-width": '400px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

SwiperCore.use([Keyboard, Scrollbar, Mousewheel, Navigation, Pagination, Controller, Thumbs]);

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [article, setArticle] = useState({title: 'Artikel wird geladen'})
  const [sectionData, setSectionData] = useState([])
  const [selectedComplexity, setSelectedComplexity] = useState('basic')//(['basic','advanced','expert'][Math.floor(Math.random()*3)])


  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    const effectFunction = async () => {
      params.id = params.id || '7bbb67f9-02a3-4d70-b84a-564c2148ab9b'
      if (params.id && !sectionData.length) {
        const result = await axios.get(`https://modular-cms.cultway.io/items/articles/${params.id}`)
        setArticle(result.data.data)
        const sectionD = await Promise.all(result.data.data.sections.map(async id => {
          const section = (await axios.get(`https://modular-cms.cultway.io/items/sections/${id}`)).data.data
          const moduleD = await Promise.all(section.modules.map(async (id) => {
            return (await axios.get(`https://modular-cms.cultway.io/items/modules/${id}`)).data.data
          }))
          section.modules = moduleD
          return section
        }))
        setSectionData(sectionD, [params.id])
        }
      }
      effectFunction()
  })

  const slides = sectionData.map((data, i) =>
  <div>
    <SwiperSlide key={`slide-${i}`} tag="div" className="horizontal-slide">
      <div className="section-wrapper">
        <div className="image-section">
          <img
            className="section-img"
            src={`https://modular-cms.cultway.io/assets/${data.image}`}
            style={{ listStyle: 'none' }}
            alt={`Slide ${i}`}
          />
          {data.imageSource && <p className="image-source">{data.imageSource}</p>}
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Button className="settings-button" onClick={handleOpen}>Einstellungen</Button>
          </div>
        </div>
        <div className="modules-section no-scrollbar">
        <div className="scroll-container" horizontal={false}>
            <div
              id={"vertical-"+i}
              className="vertical-wrapper"
          >
          <div key={`slide-${i}`} tag="div" className="vertical-slide" >
            <div className="module-wrapper section-title">
              <div className="section-title-inner"><p>{data.headline}</p></div>
              </div>
            </div>
            {sectionData[i].modules
              .filter(module => module.complexity_selection.includes(selectedComplexity))
              .map((module, j) => {
                return <Module
                  title={module.key_idea}
                  cta={module.ctaLabel}
                  body={module.text}
                  >
                </Module>})
            }
          </div>
        </div>
        </div>
      </div>
      </SwiperSlide>
  </div>
  );
  

  return (
    <React.Fragment >
      <div className="center">
        <div className="total-wrapper">

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
              <h3>Artikel personalisieren</h3>
              <p>Wie gut kennst du dich mit der DART-Mission aus?</p>   
              <FormControl component="fieldset">
                <RadioGroup row aria-label="complexity" name="controlled-radio-buttons-group"
                  value={selectedComplexity} onChange={(event) => {
                    setSelectedComplexity(event.target.value)
                  }}
                >
                  <FormControlLabel value="basic" control={<Radio className='radio-group' />} label="Gar nicht" />
                  <FormControlLabel value="advanced" control={<Radio className='radio-group' />} label="Gut" />
                  <FormControlLabel value="expert" control={<Radio className='radio-group' />} label="Sehr gut" />
                </RadioGroup>
              </FormControl>
              <Button className="settings-button" size='large' onClick={handleClose}>Übernehmen</Button>
            </Box>
          </Modal>
          <h1 className="article-title">{article.title}</h1>
          <Swiper
            id="main"
            tag="section"
            wrapperTag="div"
            direction='horizontal'
            className="horizontal-wrapper"
            navigation
            pagination={{
              type: 'progressbar'
            }}
            spaceBetween={0}
            slidesPerView={1}
            keyboard
            onInit={(swiper) => console.log('Swiper initialized!', swiper)}
            onSlideChange={(swiper) => {
              console.log('Slide index changed to: ', swiper.activeIndex);
            }}
            onReachEnd={() => console.log('Swiper end reached')}
          >
            {slides}
          </Swiper>
        </div>
      </div>
    </React.Fragment>
  );
}

const Module = ({title, cta, body}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [wasOpened, setWasOpened] = useState(false)
  return <div className={`module-wrapper ${wasOpened?'opened-module-wrapper':''}`} onClick={() => {
    isOpen && !wasOpened && setWasOpened(true)
    setIsOpen(!isOpen)
    }}>
      <h3 className="module-title">{title}{(wasOpened) ? <>&nbsp;✔</> : ''}</h3>
      {!isOpen && !wasOpened && <p className="module-cta"> {cta}</p>}
      <Collapse isOpened={isOpen}>
        <p className="module-body">
          {body}
        </p>
      </Collapse>
  </div>
}


export default App;
